export default {

  beforeMount: (el, { value, arg }) => {
    el.clickOutsideEvent = event => {
      if (!(el == event.target || el.contains(event.target)))
        if (typeof value == 'function') value()
    }
    document.addEventListener(arg ?? "mousedown", el.clickOutsideEvent)
  },
  
  unmounted: (el, { arg }) => {
    document.removeEventListener(arg ?? "mousedown", el.clickOutsideEvent)
  }

}