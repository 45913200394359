<template>
<div class="bar-horizontal"
  ref="root">

  <svg class="bar-horizontal__svg"
    ref="svg">
    <g v-for="(e,i) in dataNormalized">
      <rect class="bar-horizontal__rect"
        v-if="data[i]"
        :x="`${dataPartialSums[i] + +gap}%`"
        :width="`${Math.max(e - +gap, 0)}%`"
        :fill="colors[i]">
      </rect>
    </g>
  </svg>

</div>
</template>

<script>
export default {
  name: 'barHorizontal',
  props: {
    data: { type: Array, required: true, default: [100] },
    colors: { type: Array, required: true, default: ["#fff"] },
    gap: { type: [Number, String], required: false, default: 0 },
    height: { type: String, required: false, default: '100%' },
    duration: { type: String, required: false, default: '200ms' },
  },
  mounted() {
    this.$nextTick(_ => this.onResize())
    window.addEventListener('resize', this.onResize)
  },
  unmounted() {
    window.removeEventListener('resize', this.onResize)
  },
  computed: {
    dataSum() {
      return this.data.reduce((a,b) => a+b)
    },
    dataNormalized() {
      return this.data.map(e => e / this.dataSum * 100)
    },
    dataPartialSums() {
      return this.dataNormalized.map((e,i,arr) => arr.slice(0,i).reduce((a,b) => a+b, 0))
    }
  },
  methods: {
    onResize() {
      const root = this.$refs.root
      const svg = this.$refs.svg
      const w = root.clientWidth
      const h = root.clientHeight
      this.$nextTick(_ => svg.setAttribute('viewBox', `0 0 ${w} ${h}`))
    }
  },
}
</script>

<style lang="scss" scoped>
.bar-horizontal {
  width: 100%;
  &__svg {
    display: block;
    width: 100%;
    height: v-bind(height);
  }
  &__rect {
    height: 100%;
    transform: translate(calc(v-bind(gap) * -1) 0);
    transition: v-bind(duration) linear;
  }
}
</style>