import { createRouter, createWebHistory } from 'vue-router'
import posthog from 'posthog-js'
import store from './store'
import instance from '@/api/instance.js'

let routes = [
  {
    path: '/',
    name: 'route-main',
    prefix: 'multiple',
    page:  'landing',
    meta: { requiresAuth: true },
    beforeEnter: async (to, from, next) => {
      if (localStorage.getItem('accessToken')) {
        next({ name: 'route-student' })
      }
      next()
    }
  },
  {
    path: '/callback/login',
    name: 'route-callback-login',
    beforeEnter: async (to, from, next) => {
      localStorage.setItem('accessToken', true)
      await store.dispatch('getUserdata')
      const { groupId, originalUrl } = to.query
      if (groupId) {
        await instance.post('/join-group', {
          email: store.state.userdata?.email,
          groupId,
        })
      }
      next(originalUrl ?? '/')
    }
  },
  {
    path: '/student',
    prefix: 'multiple',
    page:  'page-main',
    meta: { requiresAuth: true },
    props: {
      tabs: [
        { icon: "bookmark", label: "Курси", route: 'route-student', isSubjectAgnostic: true },
        { icon: "event", label: "Календар", route: 'route-student-calendar' },
      ],
    },
    children: [
      {
        path: 'courses',
        name: 'route-student',
        prefix: 'student',
        page:  'page-student-courses',
        dispatch: 'getCourses',
      },
      {
        path: 'calendar/:subjectId',
        name: 'route-student-calendar',
        prefix: 'multiple',
        page:  'page-calendar',
      },
    ]
  },
  { 
    path: '/teacher',
    prefix: 'multiple',
    page: 'page-main',
    meta: { requiresAuth: true },
    props: {
      tabs: [
        { icon: "bookmark", label: "Курси", route: 'route-teacher', isSubjectAgnostic: true },
        { icon: "group", label: "Мої групи", route: 'route-teacher-groups' },
        { icon: "event", label: "Календар", route: 'route-teacher-calendar' },
      ],
    },
    children: [
      {
        path: 'courses',
        name: 'route-teacher',
        prefix: 'teacher',
        page:  'page-teacher-courses',
        dispatch: 'getCourses',
      },
      {
        path: 'groups/:subjectId',
        name: 'route-teacher-groups',
        prefix: 'multiple',
        page:  'page-groups',
      },
      {
        path: 'calendar/:subjectId',
        name: 'route-teacher-calendar',
        prefix: 'multiple',
        page:  'page-calendar',
      },
    ]
  },
  { 
    path: '/admin',
    prefix: 'multiple',
    page: 'page-main',
    meta: { requiresAuth: true },
    props: {
      tabs: [
        { icon: "bookmark", label: "Курси", route: 'route-admin', isSubjectAgnostic: true },
        { icon: "groups", label: "Групи", route: 'route-admin-groups' },
        { icon: "event", label: "Календар", route: 'route-admin-calendar' },
        { icon: "summarize", label: "Звіт", route: 'route-admin-summary' },
        { icon: "person", label: "Користувачі", route: 'route-admin-students' },
      ],
    },
    children: [
      {
        path: 'courses',
        name: 'route-admin',
        prefix: 'admin',
        page:  'page-admin-courses',
        dispatch: 'getCourses',
      },
      {
        path: 'students/:subjectId',
        name: 'route-admin-students',
        prefix: 'admin',
        page:  'page-admin-students',
      },
      {
        path: 'groups/:subjectId',
        name: 'route-admin-groups',
        prefix: 'multiple',
        page:  'page-groups',
      },
      {
        path: 'calendar/:subjectId',
        name: 'route-admin-calendar',
        prefix: 'multiple',
        page:  'page-calendar',
      },
      {
        path: 'roles/:subjectId',
        name: 'route-admin-roles',
        prefix: 'admin',
        page:  'page-admin-roles',
      },
      {
        path: 'summary/:subjectId',
        name: 'route-admin-summary',
        prefix: 'admin',
        page:  'page-admin-summary',
        dispatch: 'getStatsSubject'
      },
    ]
  },
  { 
    path: '/superadmin',
    prefix: 'multiple',
    page: 'page-main',
    meta: { requiresAuth: true },
    props: {
      tabs: [
        { icon: "bookmark", label: "Курси", route: 'route-superadmin', isSubjectAgnostic: true },
        { icon: "groups", label: "Групи", route: 'route-superadmin-groups' },
        { icon: "event", label: "Календар", route: 'route-superadmin-calendar' },
        { icon: "settings", label: "Налаштування", route: 'route-superadmin-subject-settings' },
        { icon: "video_library", label: "Медіатека", route: 'route-superadmin-media', isSubjectAgnostic: true },
      ],
    },
    children: [
      {
        path: 'courses',
        name: 'route-superadmin',
        prefix: 'admin',
        page:  'page-admin-courses',
        dispatch: 'getCourses',
      },
      {
        path: 'groups/:subjectId',
        name: 'route-superadmin-groups',
        prefix: 'multiple',
        page:  'page-groups',
      },
      {
        path: 'calendar/:subjectId',
        name: 'route-superadmin-calendar',
        prefix: 'multiple',
        page:  'page-calendar',
      },
      {
        path: 'settings/:subjectId',
        name: 'route-superadmin-subject-settings',
        prefix: 'superadmin',
        page:  'page-superadmin-subject-settings',
      },
      {
        path: 'media',
        name: 'route-superadmin-media',
        prefix: 'superadmin',
        page:  'page-superadmin-media',
      },
    ]
  },
  { 
    path: '/auth',
    name: 'route-signin',
    prefix: 'multiple',
    page: 'page-signin',
    meta: { keepAlive: true },
  },
  { 
    path: '/recall',
    name: 'route-password-recall',
    prefix: 'multiple',
    page: 'page-password-recall',
  },
  { 
    path: '/join/:groupId',
    name: 'route-group-join',
    prefix: 'multiple',
    props: to => ({ groupId: to.params.groupId }),
    page: 'page-group-join',
    beforeEnter: async to => await store.dispatch('getGroup', { groupId: to.params.groupId })
  },
  { 
    path: '/confirm',
    name: 'confirm',
    prefix: 'multiple',
    page: 'page-email-confirm',
    meta: { keepAlive: true },
  },
  { 
    path: '/support',
    prefix: 'multiple',
    page: 'page-support',
    meta: { keepAlive: true },
  },
  { 
    path: '/privacy',
    prefix: 'multiple',
    page: 'page-privacy',
    meta: { keepAlive: true }
  },
  {
    path: '/certificate/:role?/:subjectId/:courseId',
    name: 'route-certificate',
    prefix: 'student',
    page:  'page-student-certificate',
    beforeEnter: async (to, from) => {
      await store.dispatch('getCertificate', { userId: to.query.userId, courseId: to.params.courseId })
    }
  },
  {
    path: '/certificate-check/:role?',
    name: 'route-certificate-check',
    prefix: 'multiple',
    page:  'page-certificate-check',
  },
  {
    path: '/certificate-printable/:subjectId?/:courseId?',
    name: 'route-certificate-printable',
    prefix: 'multiple',
    page:  'page-certificate-printable',
    props: to => ({ userId: to.query.userId, courseId: to.params.courseId, lang: to.query.lang }),
  },
  {
    path: '/course/:role(student)?/:subjectId/:courseId',
    name: 'route-student-course',
    prefix: 'student',
    page:  'page-student-course',
    meta: { requiresAuth: true },
    props: to => ({ courseId: to.params.courseId }),
    beforeEnter: async (to, from, next) => {
      const subject = store.state.subject
      const courseId = to.params.courseId
      const certificate = store.state.certificate
      next()
      // if (certificate?.certificateId && !subject.courseCompletedIds?.includes(courseId)) 
      //   next({
      //     name: 'route-survey',
      //     params: { type: 'outro', subjectId: to.params.subjectId, courseId }
      //   })
      // else next()
    },
  },
  {
    path: '/lessons/:role(student)?/:subjectId/:courseId',
    name: 'route-course-student',
    prefix: 'student',
    page: 'page-student-lessons',
    meta: { requiresAuth: true },
    props: to => ({
      module: to.query.module,
      lessonId: to.query.lessonId,
    }),
    beforeEnter: async (to, from, next) => {
      // const subject = store.state.subject
      // const courseId = to.params.courseId
      // if(!subject.courseEnteredIds?.includes(courseId)) 
      //   next({
      //     name: 'route-survey',
      //     params: { type: 'intro', subjectId: to.params.subjectId, courseId }
      //   })
      // else {
        await store.dispatch('getLessons', { courseId: to.params.courseId })
        next()
      //}
    }
  },
  {
    path: '/course/:role(teacher)/:subjectId/:courseId',
    name: 'route-course-teacher',
    prefix: 'teacher',
    page:  'page-teacher-course',
    meta: { requiresAuth: true },
    beforeEnter: async (to, from) => {
      await store.dispatch('getLessons', { courseId: to.params.courseId })
    }
  },
  {
    path: '/course/:role/:subjectId/:courseId',
    name: 'route-course-admin',
    prefix: 'admin',
    page:  'page-admin-course',
    meta: { requiresAuth: true },
    beforeEnter: async (to, from) => {
      await store.dispatch('getLessons', { courseId: to.params.courseId })
    }
  },
  {
    path: '/course-stats/:role/:subjectId/:courseId',
    name: 'route-course-stats',
    prefix: 'multiple',
    page:  'page-course-stats',
    meta: { requiresAuth: true, keepAlive: true },
    props: (to, from) => ({
      courseId: to.params.courseId,
    }),
  },
  {
    path: '/survey/:subjectId/:courseId/:type(intro|outro)',
    name: 'route-survey',
    prefix: 'student',
    page:  'page-student-course-survey',
    meta: { requiresAuth: true, keepAlive: true },
    props: (to, from) => ({
      type: to.params.type,
      subjectId: to.params.subjectId,
      courseId: to.params.courseId,
    }),
  },
  {
    path: '/lesson/:role(student)?/:subjectId/:lessonId',
    name: 'route-lesson',
    prefix: 'student',
    page: 'page-student-quiz',
    meta: { requiresAuth: true, keepAlive: true },
    props: (to, from) => ({
      lessonId: to.params.lessonId,
      taskIndex: to.query.task,
    }),
    beforeEnter: async (to, from) => {
      await store.dispatch('getTasks', { lessonId: to.params.lessonId })
    }
  },
  {
    path: '/lesson/:role(admin|superadmin)/:subjectId/:lessonId',
    name: 'route-lesson-editable',
    prefix: 'admin',
    page: 'page-admin-quiz',
    meta: { requiresAuth: true },
    props: (to, from) => ({
      lessonId: to.params.lessonId,
      taskId: to.query.taskId,
    }),
    beforeEnter: async (to, from) => {
      await store.dispatch('getTasks', { lessonId: to.params.lessonId })
    }
  },
  { 
    path: '/lesson-stats/:role/:subjectId/:lessonId',
    name: 'route-lesson-stats',
    prefix: 'multiple',
    page: 'page-lesson-stats',
    meta: { requiresAuth: true },
    props: (to, from) => ({
      lessonId: to.params.lessonId,
    }),
  },
  { 
    path: '/user/:role?/:subjectId/:courseId',
    name: 'route-user',
    prefix: 'multiple',
    page: 'page-user',
    meta: { requiresAuth: true },
    props: (to, from) => ({
      courseId: to.params.courseId,
      userId: to.query.userId,
    })
  },
  { 
    path: '/tip/:role?/:subjectId/:tipId',
    name: 'route-tip',
    prefix: 'student',
    page: 'page-student-tip',
    meta: { requiresAuth: true },
  },
  { 
    path: '/tip-printable/:subjectId/:tipId',
    name: 'route-tip-printable',
    prefix: 'multiple',
    page: 'page-tip-printable',
    props: (to, from) => ({
      tipId: to.params.tipId,
    }),
  },
  { 
    path: '/settings/:role(student|teacher)',
    name: 'route-settings',
    prefix: 'multiple',
    page: 'page-settings',
    meta: { requiresAuth: true },
  },
  { 
    path: '/onboarding/student',
    name: 'route-student-onboarding',
    prefix: 'student',
    page: 'page-student-setup',
    beforeEnter: (to, from, next) => {
      if (store.state.userdata.isInitialized) next('/')
      else next()
    }
  },
  { 
    path: '/onboarding/teacher',
    name: 'route-teacher-onboarding',
    prefix: 'teacher',
    page: 'page-teacher-setup',
    beforeEnter: (to, from, next) => {
      if (store.state.userdata.isTeacherInitialized) next('/')
      else next()
    }
  },
  { 
    path: "/:catchAll(.+)", 
    prefix: 'multiple',
    page: 'page-404',
  }
]

const routeParse = function(route) {
  if (route.page) route.component = async _ => await import(`./pages/${route.prefix}/${route.page}.vue`)
  if (route.dispatch) route.beforeEnter = async _ => await store.dispatch(route.dispatch, {})
  if (route.children) route.children = route.children.map(e => routeParse(e))
  return route
}

routes = routes.map(e => routeParse(e))

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { top: 0 }
  },
  routes
})

router.onError((error, to) => {
  if (import.meta.env.VITE_ENV != 'dev') return
  if (
    error.message.includes('Failed to fetch dynamically imported module')
    || error.message.includes('Importing a module script failed')
  ) {
    console.error('Router error:', error)
    const currentTimestamp = new Date().getTime()
    let newLocation = to.fullPath
    if (!to?.fullPath) newLocation = window.location.pathname
    if (newLocation.includes('?')) newLocation += `&reload=${currentTimestamp}`
    else newLocation += `?reload=${currentTimestamp}`
    window.location.href = newLocation
  }
})

let interval

router.beforeEach(async (to, from, next) => {

  // store.state.totalResources = 0
  // store.state.loadedResources = 0
  // store.state.totalResources = 100
  // window.performance.clearResourceTimings()
  interval = setInterval(_ => {
    store.state.loadingProgress += .5 ** store.state.loadingStep++
  }, 200)

  const isAuthorized = localStorage.getItem('accessToken')

  if(store.state.isPageUnloadPrevented) {
    const res = window.confirm('На сторінці є незбережені зміни. Ви дійсно хочете її залишити?')
    if (res) next()
    else return next(false)
  }

  if (to.meta?.requiresAuth && !isAuthorized) {
    if (to.name!='route-main') return next({ name: 'route-signin' })
  }

  window.faro?.api?.setView({
    name: window.location.href
  })

  let role = to.params.role ?? to.path.split('/')[1]
  role = ['student', 'teacher', 'admin', 'superadmin'].includes(role) ? role : 'student'
  const subjectId = to.params?.subjectId

  const rolePrev = store.state.role
  if (role != rolePrev) {
    store.commit('setRole', role)
    if (isAuthorized) {
      await Promise.all([
        store.dispatch('getUserdata'),
        store.dispatch('getSubject'),
      ])
      posthog.identify(
        store.state.userdata._id,
        { email: store.state.userdata.email, role }
      )
      window?.faro?.api?.setUser({
        _id: store.state.userdata._id,
        email: store.state.userdata.email,
        attributes: { role },
      })
    }
  }

  const subjectIdPrev = store.state.subjectId
  if (subjectId && subjectId!=subjectIdPrev) {
    store.commit('setSubjectId', subjectId)
    await store.dispatch('getSubject')
  }
  
  if (isAuthorized && role=='student' && !store.state.userdata.isInitialized && to.name != 'route-student-onboarding')
    return next({ name: 'route-student-onboarding' })
  
  if (isAuthorized && role=='teacher' && !store.state.userdata.isTeacherInitialized && to.name != 'route-teacher-onboarding')
    return next({ name: 'route-teacher-onboarding' })

  next()

})

router.afterEach(_ => {
  //setTimeout(_ => store.state.totalResources = window.performance.getEntriesByType('resource')?.length, 100)
  store.state.loadingStep = 1
  store.state.loadingProgress = null
  clearInterval(interval)
  document.body.style.overflowY = 'auto'
  store.commit('preventUnload', false)
  store.commit('showLoader', false)
})

export default router